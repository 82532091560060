<!--消费模块--布局主页-->
<template>
  <div id="consumeindex">
    <div class="header" v-show="this.$route.meta.hidden">
      <my-indextitle :imgurl="imgurl">{{ title }}</my-indextitle>
      <my-navdar :list="list"></my-navdar>
    </div>
    <div class="main">
      <transition name="slide-fade">
        <keep-alive :exclude="cancelCache">
          <router-view class="view"></router-view>
        </keep-alive>
      </transition>
    </div>
  </div>
</template>

<script>
import indexTitle from "../../components/Title/indexTitle";
import Navdar from "../../components/Navdar/horizontal";

export default {
  data() {
    return {
      cancelCache: ["terminaleditor", "fireEdit", "outputEdit"],
      imgurl: require("../../../public/images/moduleimg/consumption.png"),
      list: JSON.parse(sessionStorage.getItem("sitemenu")) || [],
    };
  },
  watch: {
    // 监听多语言切换，导航栏数据同步切换
    "$store.getters.loading": {
      handler: function (newValue, oldValue) {
        const value = this.$route.query.Code;
        if (!newValue) {
          this.$store.commit("menu/SITE_MENU", value);
          this.list = this.$store.getters.siteMenu;
        }
      },
      deep: true,
    },
  },
  // 获取名称
  computed: {
    title: function () {
      let name = this.$store.getters.siteServiceTypeName;
      return name;
    },
  },
  // 导航栏数据初始化
  mounted() {
    const value = this.$store.getters.loading;
    const Code = this.$route.query.Code;
    if (!value) {
      this.$store.commit("menu/SITE_MENU", Code);
      this.list = this.$store.getters.siteMenu;
    }
  },
  components: {
    "my-indextitle": indexTitle,
    "my-navdar": Navdar,
  },
};
</script>

<style scoped>
#consumeindex {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 10px #bdbcbc;
  background: #ffffff;
  width: calc(100% - 40px);
  height: calc(100% - 30px);
  overflow: hidden;
}
.main {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.header {
  height: 100px;
  padding: 0px 20px 0px 20px;
}
.view {
  position: absolute;
  width: 100%;
  height: 100%;
}
.slide-fade-enter-active {
  transition: all 0.8s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
</style>